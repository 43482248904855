<template>
  <form data-vv-scope="timetable-form">
    <v-slide-group class="pa-2" id="timetableUi">
      <v-slide-item
        v-for="(day, n) in timetable"
        :key="n"
        v-slot="{ active }"
        :style="[{ order: fromTime > toTime && n < fromTime ? 1 : 0 }]"
      >
        <v-card
          v-if="n >= fromTime || n <= toTime || !to || !from"
          align="center"
          :color="active"
          style="border: 1px solid var(--v-primary-base)"
          class="ma-4"
          :height="size"
          width="200"
          elevation="5"
        >
          <v-card-title class="white--text text-uppercase">
            {{ $t("week_days")[n + 1] }}

            <td :key="i" style="text-align: center" class="actions">
              <v-icon
                v-if="!disable"
                @click="add(n)"
                class="mr-2 primary--text"
                :disabled="daysAvailable ? !daysAvailable[n - 1] : false"
                >mdi-plus-circle</v-icon
              >

              <v-icon
                v-if="!disable && copy_index == null"
                class="primary--text"
                @click="copy(n)"
                :disabled="daysAvailable ? !daysAvailable[n - 1] : false"
                >mdi-content-copy</v-icon
              >

              <v-btn
                small
                v-else-if="!disable"
                class="pa-4"
                outlined
                @click="paste(n)"
                :disabled="copy_index == n"
              >
                <v-icon>mdi-content-paste</v-icon>
              </v-btn>
            </td>
          </v-card-title>

          <v-card-text :style="[{ height: size - 35 + 'px' }]">
            <v-row align="center" style="height: 100%">
              <v-col cols="12" v-for="(t, i) of day" :key="i">
                <v-row>
                  <v-col>
                    <time-input
                      :value="t"
                      :valueKey="0"
                      :disabled="disable"
                    ></time-input>
                  </v-col>

                  <v-col>
                    <time-input
                      :value="t"
                      :valueKey="1"
                      :disabled="disable"
                      @blur="createError"
                    ></time-input>
                  </v-col>
                  <v-btn
                    style="margin: auto"
                    x-small
                    icon
                    @click="remove(day, i)"
                    v-if="!disable"
                    ><v-icon style="color: red">mdi-close</v-icon></v-btn
                  >
                </v-row>
              </v-col>
              <v-col cols="12" v-if="day.length == 0" class="text-uppercase">{{
                $t("closed")
              }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <!--
    <v-simple-table>
      <thead>
        <tr>
          <th
            v-for="n in (1, 7)"
            :key="n"
            style="
                color: var(--v-primary-base) !important;
                font-size: 12px;
                text-align: center;
                white-space: nowrap;
              "
            :disabled="disable"
          >
            {{ $t("week_days")[n] }}
          </th>
        </tr>
      </thead>
      <tbody v-if="max" style="text-align: center">
        <tr :disabled="disable" v-for="n in (1, max)" :key="n">
          <td v-for="m in (1, 7)" :key="m">
            <span v-if="timetable[m - 1][n - 1]">
              <time-input
                :value="timetable[m - 1][n - 1]"
                :valueKey="0"
                :disabled="disable"
                :error="error_time[m - 1][n - 1]"
              ></time-input>
              <time-input
                :value="timetable[m - 1][n - 1]"
                :valueKey="1"
                :disabled="disable"
                :error="error_time[m - 1][n - 1]"
                @blur="createError"
              ></time-input>
              <v-btn
                style="margin: auto"
                x-small
                icon
                @click="remove(m - 1, n - 1)"
                v-if="!disable"
                ><v-icon style="color: red">mdi-close</v-icon></v-btn
              >
            </span>
            <span v-else>-</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td v-for="n in (1, 7)" :key="n" style="text-align: center">
            <v-btn
              small
              v-if="!disable"
              fab
              outlined
              @click="add(n - 1)"
              class="mr-1"
              :disabled="daysAvailable ? !daysAvailable[n - 1] : false"
              ><v-icon>mdi-plus-circle</v-icon></v-btn
            >
            <v-btn
              small
              v-if="!disable && !copy_index"
              fab
              outlined
              @click="copy(n)"
              :disabled="daysAvailable ? !daysAvailable[n - 1] : false"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>

            <v-btn
              small
              v-else-if="!disable"
              fab
              class="pa-4"
              outlined
              @click="paste(n)"
              :disabled="copy_index == n"
            >
              <v-icon>mdi-content-paste</v-icon>
            </v-btn>
          </td>
        </tr>
      </tfoot>
    </v-simple-table>-->
  </form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Timetable",
  props: [
    "timetable",
    "timetableLimit",
    "daysAvailable",
    "disable",
    "from",
    "to",
  ],
  components: { TimeInput: () => import("./TimeInput") },
  data() {
    return { error_time: [[], [], [], [], [], [], []], copy_index: null };
  },
  watch: {
    timetable: {
      handler() {
        //this.createError();
      },
      deep: true,
    },
  },
  methods: {
    ...mapGetters("app", ["dayOfWeek"]),
    createError() {
      if (this.timetable)
        this.error_time = this.timetable.map((days) => {
          return days.map((x) => x[0] === null || x[1] === null || x[0] > x[1]);
        });
    },
    add(i) {
      this.timetable[i].push([null, null]);
    },
    copy(i) {
      this.copy_index = i;
      console.log("COPY", this.copy_index);
    },
    paste(i) {
      console.log(this.timetable);
      console.log(this.timetable[this.copy_index]);
      this.timetable[i] = this.timetable[this.copy_index];
      this.copy_index = null;
    },
    remove(t, i) {
      //console.log("del", i, n, this.timetable[i][n]);
      t.splice(i, 1);
    },
    check() {
      let solap = false,
        bigger = true,
        studio_time = true;
      let error = false;
      for (let i = 0; i < 7; ++i) {
        let error_day = false;
        this.timetable[i].forEach((t) => {
          let error_section = true;
          console.log("<=========CHEGING============>");
          console.log("SECTION: ", t);

          this.timetableLimit[i].forEach((tl) => {
            console.log("TEST", tl);
            if (t[0] >= tl[0] && t[1] <= tl[1]) {
              console.log("DENTRO");
              error_section = false;
            }
          });
          if (error_section) error_day = true;
        });
        if (error_day) error = true;
      }

      if (error) {
        this.$alert(this.$t("errors.studio_timetable"));
      }
      return !error;
    },
  },
  computed: {
    fromToDiff() {
      let from = new Date(this.from);
      let to = new Date(this.to);
      let diff = to.getTime() - from.getTime();
      return Math.ceil(diff / (1000 * 3600 * 24));
    },
    fromTime() {
      if (this.from && this.fromToDiff < 7) return this.dayOfWeek()(this.from);
      return 0;
    },
    toTime() {
      if (this.to && this.fromToDiff < 7) return this.dayOfWeek()(this.to);
      return 6;
    },
    max() {
      if (this.timetable) {
        let lengths = this.timetable.map((t) => t.length);

        return Math.max(...lengths);
      }
      return 0;
    },
    size() {
      let timetableSize = 100;
      console.log("MAX", this.max);
      if (this.max == 0) {
      } else if (this.max == 1) {
        timetableSize *= 2;
      } else {
        timetableSize *= this.max + 0.7;
      }
      return timetableSize;
    },
  },
};
</script>

<style lang="sass">
.actions
  position: absolute
  top: 5px
  right: 10px

#timetable
  table
    min-width: 600px
</style>
